<template lang="pug">
v-app
  t-transition-overlay
  v-app-bar.t-header.pr-0(app dark Zdense flat Zhide-on-scroll clipped-left clipped-right
    Zcolor="appBarColor"
    ZZcolor="#373740"
    :color="appBarColor"
    Zextended
    height="47"
    style="padding-top: env(safe-area-inset-top)"
    extension-height="24")

    template(v-slot:extension v-if="!!$slots['header-extension']")
      slot(name="header-extension")

    slot(name="header-layout")

      v-app-bar-nav-icon(:ripple="false" @click.stop="setDrawer(!drawer)")
      //- p [ {{$root.safeArea}} ]
      v-badge(v-if="!drawer" color="red" overlap offset-x="25" offset-y="1" icon="mdi-hexagram")
      v-badge(color="primary" overlap offset-x="25" offset-y="1" :content="unreadCount" :value="!drawer && unreadCount > 0")

      //- v-progress-linear(:active="loading" :indeterminate="loading" absolute bottom)
      slot(name="header")

        v-spacer

        v-toolbar-title.cash-register-name.mx-2.align-center(v-if="!$vuetify.breakpoint.lgAndUp")
          span {{ cashRegister.name ? cashRegister.name : organization.businessName }}

        v-spacer

        v-btn(id="account_button" icon)
          v-icon mdi-account
          v-menu(bottom left activator="#account_button")
            v-list
              v-list-item-group
                v-list-item(to="/profile" :disabled="!user._links.createCashRegister")
                  v-list-item-content
                    v-list-item-title {{ user.phone | phone }}
                    v-list-item-subtitle(v-if="user.name") {{ user.name }}
                  v-list-item-icon
                    v-icon mdi-account-box-outline
                v-list-item(@click="signOutConfirm()")
                  v-list-item-content
                    v-list-item-title {{ $t("выход") }}
                  v-list-item-icon
                    v-icon mdi-exit-to-app

  DrawerLeft(v-model="drawer")

  v-main
    v-container.align-start.pa-0(fluid Zfill-height style="margin-top: env(safe-area-inset-top)")
      .flex-grow-1.fill-height
        slot

  v-footer.pa-2(
    v-if="$slots.footer" app Zabsolute inset bottom padless Zoutlined Zfixed Zcolor="transparent"
    ZZcolor="#373740"
    color="#33333d"
    width="100%"
    style="padding-bottom: env(safe-area-inset-bottom) !important"
    Zstyle="z-index: 500;"
    Zclass="{'pb-6':$vuetify.breakpoint.mobile}")
    slot(name="footer")

  slot(name="rightdrawer")
  slot(name="dialogs")

</template>
<script>
import { mapActions, mapState } from 'vuex'

import DrawerLeft from 'T@/components/DrawerLeft.vue'

export default {
  props: {
    appBarColor: {
      type: String,
      default: '#33333d',
    },
  },
  components: {
    DrawerLeft,
  },

  data: () => ({
    loading: false,
    cashRegisterSwitchDialog: false,
    unreadCount: 0,
  }),

  computed: {
    ...mapState({
      cashRegister: (state) => state.tjtheme.cashRegister,
      organization: (state) => state.tjtheme.organization,
      user: (state) => state.tjtheme.user,
      printer: (state) => state.printer.printer,
      drawer: (state) => state.tools.drawer,
      appInitialized: (state) => state.tools.appInitialized,
      cordovaBuildVersion: (state) => state.tools.cordovaBuildVersion,
    }),
  },
  created() {
    // console.log('this.$root.safeArea', this.$root.safeArea)
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      fetchShiftByNumber: 'cashRegisters/fetchShiftByNumber',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      fetchUserCashRegisterList: 'cashRegisters/fetchUserCashRegisterList',
      fetchUserCashRegisterDeregisteredList: 'cashRegisters/fetchUserCashRegisterDeregisteredList',
      createNewCashRegister: 'cashRegisters/createNewCashRegister',
      deleteNewCashRegister: 'cashRegisters/deleteNewCashRegister',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      setDrawer: 'tools/setDrawer',
      setAppInitialized: 'tools/setAppInitialized',
      firebaseAnalyticsSetUserId: 'tools/firebaseAnalyticsSetUserId',
    }),

    signOutConfirm() {},
    showChat() {
      if (this.$vuetify.breakpoint.mdAndDown) this.setDrawer(false)
    },
    cashRegisterLoading() {},
  },

  beforeRouteLeave(to, from, next) {
    if (this.$vuetify.breakpoint.mdAndDown) this.setDrawer(false)
    next()
  },
}
</script>

<style lang="stylus">
// .v-toolbar__content, .v-toolbar__extension {
// border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
// }
.v-sheet.v-footer.isMobile
  border-radius 0 0 30px 30px
  overflow hidden
  background-color #f00 !important
</style>
