<template lang="pug">
t-window(v-bind="$attrs" v-on="$listeners" min-height="300px")
  template(slot="header") Чек #120
  template(slot="header-actions-prepend")
    t-btn.mr-1(v-if="!s.implicitClient"  @click.stop="$emit('route','contacts')" fab icon) mdi-account-plus
  template(slot="header-more" v-if="!s.implicitClient || s.trashOrOptions")
    CartOptions(v-model="s")
      t-list-item(title="Фискализировать в ОФД")
        v-switch(inset v-model="s.fiscalize")
      t-list-item(title="Способ оплаты")
        v-switch(inset v-model="s.rememberType")
      t-list-item(title="Автофокус Принято")
        v-switch(inset v-model="s.autoFocus")
      t-list-item(title="Show Скидка")
        v-switch(inset v-model="s.showDiscount")
      t-list-item(title="Показывать чек сразу")
        v-switch(inset v-model="s.afterShowCheck")

  template(slot="subheader"): CartClientActions(v-on="$listeners" :implicitClient="s.implicitClient")

  //- template(v-slot:prebody="") Выберите способ оплаты

  v-list.mt-3
    v-list-item.mb-3
      //- v-tooltip(v-model="noTypeSelected" attach=".paymenttypes" bottom :position-y="40"  color="error") Выберите тип оплаты
      PaymentTypes(v-model="type" :typeList="typeList" :autoselect="s.rememberType" width="2cols" :error="noTypeSelected")

    v-list-item
      t-input(v-model="amount" autofocus prefix="Принято" :placeholder="String(totalSum)" suffix="₸" type="number" autocomplete="cash" autocomplete-show)

  //- v-divider

  template(slot="prefooter")
    //- v-divider
    v-list.py-0(:color="s.ziStyle?undefined:'transparent'")
      t-list-item-input(label="Скидка" placeholder="0" suffix="%" icon="mdi-sale" type="number" v-model="discount" :hints="[5,10,15,20,50,70]")
    t-collapse(:value="remain > 0")
      v-list.py-0(color="transparent")
        v-list-item
          v-list-item-icon.mt-2.pl-3: v-icon mdi-database
          v-list-item-content.pt-0: v-list-item-title Сдача
          v-list-item-action.mt-0.pr-2.ezcar.headline - {{remain}} ₸

    v-divider.pb-3

    //- v-alert.mb-2(
        :value="!fiscal",
        type="error",
        transition="fade-transition",
        text,
        dense,
        Zdismissible
      ) Чек будет не фискальный!

  template(slot="footer")
    Basket(v-if="s.bottomBtn" @click="done" @click.native="validate" noIcon  no-empty :menu="show.paymentTypes" @menuHide="show.paymentTypes = false")
      //- template(slot="left-content" v-if="s.bottomBtn && s.bottomBtn.length" slot-scope="{isEmpty,disabled}")
        v-btn(color="red" v-if="s.bottomBtn == 'kaspi'" x-large depressed Zoutlined @click="$emit('route','kaspi')")
          img(src="/logo/kaspi.svg" width="36" height="36")
        v-btn(:color="fiscal?'primary':'grey'" v-else-if="s.bottomBtn == 'fiscal'" x-large depressed Zoutlined @click="fiscal=!fiscal" :outlined="!fiscal")
          v-badge(color="white" left overlap bottom icon="")
            template(slot="badge"): .primary--text ОФД
            v-icon mdi-receipt
      //- template(slot="left-inner" v-if="s.fiscalize"): v-icon mdi-receipt
      template(slot="menu" slot-scope="{menu,hide}")
        DialogBasketMenu(:value="menu" @input="hide" @select="hide")
          template(slot="header-more")
            v-list
              v-list-item-group(v-model="s.listStyle" active-class="border" color="indigo")
                v-list-item: v-list-item-content: v-list-item-title Buttons
                v-list-item: v-list-item-content: v-list-item-title List
          template(slot="title"): .subtitle-1 Выберите способ оплаты
          t-list.mt-2(:button="s.listStyle == 0")
            t-list-item(v-for="(t,k) in typeList" :icon="t.icon_img || t.icon" :icon-color="t.color" :title="k" @click="type = k; hide()")
          t-collapse(v-model="show.extra" Zhide)
            template(slot="activator")
              t-list-item.pl-4.grey--text(color="transparent" :icon="show.extra?'mdi-chevron-up':'mdi-chevron-down'" :title="show.extra?'Свернуть...':'Еще ...'" no-right @click="show.extra = !show.extra")
            t-list(color="transparent" :button="s.listStyle == 0")
              t-list-item(icon="/logo/freedom_icon.svg" title="Безналичный")
              t-list-item(icon="mdi-cash" title="Сертификат")
              t-list-item(icon="mdi-card" title="Chocolife")
              t-list-item(icon="mdi-tag-off" title="Смешанный Платеж")
      | ОПЛАТА {{type}}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import Basket from 'T@/components/cart/Basket.vue'
import CartClientActions from 'T@/components/cart/_parts/CartClientActions.vue'
import CartOptions from 'T@/components/cart/_parts/CartOptions.vue'
import PaymentTypes from 'T@/components/cart/_parts/PaymentTypes.vue'
import DialogBasketMenu from '@/TJTheme/pages/kassa/DialogBasketMenu.vue'

export default {
  components: {
    Basket,
    CartClientActions,
    DialogBasketMenu,
    CartOptions,
    PaymentTypes,
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: false,
    },
    color: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    s: {
      implicitClient: false,
      fiscalize: true,
      rememberType: false,
      autoFocus: false,
      showDiscount: true,
      bottomBtn: 'fiscal',
      afterShowCheck: false,
      listStyle: 0,
      dirs: {
        bottomBtn: {
          off: 'OFF',
          on: 'ON',
          fiscal: 'mdi-receipt',
          kaspi: 'KASPI',
          card: 'mdi-credit-card',
          creditAndCash: 'credit-and-cash',
        },
      },
    },
    typeList: {
      Наличные: { i: 0, icon: 'mdi-cash' },
      Карта: {
        i: 1, icon_img: '/logo/mastercard.svg', icon: 'mdi-credit-card', color: 'transparent',
      },
      'Kaspi QR': { i: 2, icon_img: '/logo/kaspi.svg', color: 'red' },
    },
    fiscal: false,
    type: null,
    show: {
      extra: false,
      paymentTypes: false,
    },
    amount: null,
    discount: 0,
    kaspiqr: true,
    noTypeSelected: false,
  }),
  watch: {
    '$attrs.value': function (v) {
      if (!this.type) {
        this.show.paymentTypes = false
        setTimeout(() => {
          this.show.paymentTypes = true
        }, 100)
      }
    },
  },
  computed: {
    ...mapGetters({
      cart: 'tjtheme/cart',
      totalSum: 'tjtheme/total',
    }),
    remain() {
      return this.amount - this.totalSum
    },
  },
  created() {
    this.fiscal = this.s.fiscalize
  },
  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
    }),
    done() {
      this.$reset()
      this.clearCart()
      this.$emit('route', 'progress', true)
    },
    validate() {
      if (this.type == null) {
        this.noTypeSelected = true
        setTimeout(() => { this.noTypeSelected = false }, 500)
      }
    },
  },
}
</script>
<style lang="stylus">

</style>
