<template lang="pug">
v-navigation-drawer(
  app,
  clipped,
  fixed,
  align-start,
  :value="drawer",
  @input="setDrawer"
)
  //- template(v-slot:prepend)
    //- v-list-item
      v-list-item-content.pb-0
        v-list-item-title.title {{ cashRegister.name }}
        v-list-item-subtitle.text--primary {{ organization.businessName }}
        v-list-item-subtitle {{ organization.businessId }}
        v-row
          v-col
            v-btn(v-if="user._links.createCashRegister" :class="{'blinker' : cashRegister.status === 'TRIAL' && cashRegisterSwitchDialog === false }" rounded block outlined x-small @click="cashRegisterSwitchDialog = true") {{ $t("кассы") }}
          v-col
            v-btn.d-none(rounded block outlined x-small disabled) {{ $t("пользователи") }}
  template(v-slot:append)
    .my-4(align="center")
      v-btn.nocaps.loose(outlined, rounded, color="primary")
        v-icon mdi-chat
        span.ml-2.font-weight-regular Служба Поддержки

    //- .my-4(align="center")
      v-btn(text icon color="grey" @click="$openLink('https://facebook.com/rekassa.kz', '_system')")
        v-icon mdi-facebook
      v-btn(text color="grey" icon @click="$openLink('https://t.me/reKassakz', '_system')")
        v-icon mdi-telegram
      v-btn(text color="grey" icon @click="$openLink('https://www.instagram.com/rekassa/', '_system')")
        v-icon mdi-instagram
      v-btn(text color="grey" icon @click="$openLink('https://www.youtube.com/channel/UCTQAHqUp-LYfofTExQEdDXQ', '_system')")
        v-icon mdi-youtube

    .my-5.caption.primary--text(align="center")

      img.mx-2(src="/logo/rekassa.svg", width="65", style="margin-bottom: -1px" @click="$root.splash = true")
      span &copy; 2021
        t-version

      //- v-img.my-3(src="/logo/qolailysauda.svg", width="100")
      //- a.mx-1(href="/about") reKassa.kz
      //- span by COMRUN
      //- span.mx-2 &bull;&nbsp;

  v-list
    //- v-list-item(Ztwo-line)
      v-list-item-avatar
        img(src='https://randomuser.me/api/portraits/women/11.jpg')
      //- v-list-item-content
        v-list-item-title Алибекова Нургуль
        v-list-item-subtitle Кассир
    //- v-list-item
      v-list-item-avatar
        v-img(src='https://cdn.vuetifyjs.com/images/john.png')
    v-list-item(link)
      v-list-item-avatar(@click.stop="$router.push('/apps/taxpassport')")
        img(src='/avatar.jpg')
      v-list-item-content(@click.stop="$router.push('/2gis')")
        v-list-item-title.text-h6 {{ cashRegister.name }} {{ organization.businessName }}
        v-list-item-subtitle Касса {{ organization.businessId }}
      v-list-item-action
        v-icon mdi-chevron-down

      //- v-list-item-action
        v-icon mdi-chevron-down

  v-divider

  v-list(Znav, dense)
    v-list-item(to="/contacts", :ripple="false")
      v-list-item-icon
        v-icon mdi-account-group
      v-list-item-content
        v-list-item-title {{ $t('Контакты') }}

    v-list-item(to="/kassa", :ripple="false")
      v-list-item-icon
        v-icon mdi-calculator-variant
      v-list-item-content
        v-list-item-title {{ $t('Каталог') }}
        //- v-list-item-subtitle(v-if="cashRegister.status === 'NEW'") {{ $t("без_активации") }}
        //- v-list-item-subtitle(v-if="cashRegister.status === 'TRIAL'") {{ $t("демо_режим") }}
        //- v-list-item-subtitle(v-if="cashRegister.status === 'DEREGISTERED'") {{ $t("архивная") }}
        //- v-list-item-subtitle(v-if="cashRegister.status === 'REGISTERED' && cashRegisterMode === 'OFFLINE' && hoursLeftTillBlocked !== null && hoursLeftTillBlocked >= 0") {{ $t('осталось_ч', { hours: hoursLeftTillBlocked }) }}
        //- v-list-item-subtitle(v-if="cashRegister.status === 'REGISTERED' && cashRegisterMode === 'BLOCKED'") {{ $t("заблокирована") }}
      v-list-item-action(v-if="cashRegister.status === 'REGISTERED'")
        v-chip(
          x-small,
          :color="cashRegisterMode === 'ONLINE' ? 'success' : cashRegisterMode === 'OFFLINE' ? 'warning' : cashRegisterMode === 'BLOCKED' ? 'error' : null"
        ) {{ cashRegisterMode }}

    //- v-list-item(to="/kkm/payments" :ripple="false")
      v-list-item-icon
        v-icon mdi-credit-card-outline
        v-badge(v-if="featureNewPaymentsSeen" bordered color="red" icon="mdi-hexagram" overlap)
      v-list-item-content
        v-list-item-title {{ $t("платежи") }}

    //- div.my-1

    //- v-subheader {{ $t("отчёты") }}

    v-list-item(to="/orders")
      v-list-item-icon
        v-icon mdi-book-open-page-variant
      v-list-item-content
        v-list-item-title {{ $t('Чеки') }}
        //- v-list-item-subtitle(v-if="shiftNumber") {{ $t("смена") }} №{{ shiftNumber }}
      //- v-list-item-action(v-if="revenue")
        v-list-item-action-text {{ revenue | numeral('0,0.[00]') }} ₸
      v-list-item-action(v-if="shiftNumber")
        v-list-item-action-text {{ shiftTicketNumber ? shiftTicketNumber : '0' }}

    //- v-list-item(:to="`/shifts/${cashRegister.id}`" :ripple="false")
      v-list-item-icon
        v-icon mdi-finance
      v-list-item-content
        v-list-item-title {{ $t("итоги_продаж") }}
        v-list-item-subtitle(v-if="shiftNumber") {{ $t("смена") }} №{{ shiftNumber }}
      v-list-item-action(v-if="revenue")
        v-list-item-action-text {{ revenue | numeral('0,0.[00]') }} ₸

    //- v-list-item(to="/reports" :ripple="false")
      v-list-item-icon
        v-icon mdi-file-chart
      v-list-item-content
        v-list-item-title {{ $t("отчёты") }}

    .my-1

    v-subheader {{ $t('APPS') }}

    //- v-subheader ККМ {{ cordovaBuildVersion ? `(${cordovaBuildVersion})` : '' }}

    //- v-list-item(to="/passport" :ripple="false" v-if="cashRegister.status === 'REGISTERED' || cashRegister.status === 'DEREGISTERED'")
    //-   v-list-item-icon
    //-     v-icon mdi-passport
    //-   v-list-item-content
    //-     v-list-item-title {{ $t("паспорт") }}
    //-   v-list-item-action(v-if="cashRegister")
    //-     v-list-item-action-text {{ cashRegister.serialNumber }}

    //- v-list-item(to="/kkm" :ripple="false" v-if="cashRegister.status === 'REGISTERED' || cashRegister.status === 'DEREGISTERED'")
    //-   v-list-item-icon
    //-     v-icon mdi-cog-outline
    //-   v-list-item-content
    //-     v-list-item-title {{ $t("настройки") }}
    //-     v-list-item-subtitle

    v-list-item(to="/apps/analytics", :ripple="false")
      v-list-item-icon
        v-icon mdi-television-guide
        v-badge(bordered, color="red", icon="mdi-hexagram", overlap)
      v-list-item-content
        v-list-item-title {{ $t('Аналитика') }}
      v-list-item-action
        v-chip(x-small, color="red") Free trial

    t-collapse(v-model="extra")
      template(slot="activator")
        v-list-item.grey--text(@click="extra = !extra")
          v-list-item-icon: v-icon(color="grey") {{extra?'mdi-chevron-up':'mdi-chevron-down'}}
          v-list-item-content: v-list-item-title {{extra?'Свернуть...':'Еще ...'}}
      v-list-item(to=" /apps/taxpassport")
        v-list-item-icon: v-icon mdi-passport
        v-list-item-content: v-list-item-title Taxpassport
      v-list-item(to=" /taplink")
        v-list-item-icon: v-icon mdi-storefront
        v-list-item-content: v-list-item-title Taplink

    //- v-list-item(link)
      v-list-item-icon
        v-icon mdi-chevron-down
      v-list-item-content
        v-list-item-title Еще ...

    //- v-list-group(:value='false' reverse prepend-icon='Zmdi-account-circle')
      template(v-slot:activator='')
        v-list-item-title Еще ...
      v-list-item(to="/apps/analytics" :ripple="false")
        v-list-item-icon
          v-icon mdi-television-guide
        v-list-item-content
          v-list-item-title {{ $t("Аналитика") }}

    .my-1

    v-subheader

    v-list-item(to="/settings", :ripple="false")
      v-list-item-icon
        v-icon mdi-cog-outline
      v-list-item-content
        v-list-item-title {{ $t('настройки') }}

    v-list-item(to="/ui", :ripple="false")
      v-list-item-icon
        v-icon(color="amber") mdi-hexagram
      v-list-item-content
        v-list-item-title UI

    //- v-list-item(to="/printer" :ripple="false")
      v-list-item-icon
        v-icon mdi-printer
      v-list-item-content
        v-list-item-title {{ $t("принтер") }}
        v-list-item-subtitle(v-if="printer.connectionType === 'bluetooth' && printer.bluetooth.name") {{ printer.bluetooth.name }}
        v-list-item-subtitle(v-if="printer.connectionType === 'wifi' && printer.wifi.name") {{ printer.wifi.name }}

    //- v-list-item.disable-active-highlight(:ripple="false" @click="showManual()")
      v-list-item-icon
        v-icon mdi-book-information-variant
      v-list-item-content
        v-list-item-title {{ $t("инструкция") }}

    //- v-list-item(:ripple="false" active-class="disable-active-class")
      v-list-item-icon
        v-icon mdi-chat
      v-list-item-content
        v-list-item-title {{ $t("поддержка") }}
      v-list-item-action(v-if="unreadCount > 0")
        v-chip(color="primary" x-small :class="{ 'shake' : drawer }") {{ unreadCount }}
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},
  data: () => ({
    extra: false,
    appBarColor: null,
    loading: false,
    shiftNumber: 1,
    revenue: 1000,
    shiftTicketNumber: 5,
    cashRegisterSwitchDialog: false,
    unreadCount: 0,
    faceTouchIdType: null,
    featureNewPaymentsSeen: (localStorage.getItem('rekassa.kz-menu-feature-new-payments-seen') || 'false') === 'false',
    featureMoyuchetSeen: (localStorage.getItem('rekassa.kz-menu-feature-moyuchet-seen') || 'false') === 'false',
    featurePositionsSeen: (localStorage.getItem('rekassa.kz-menu-feature-positions-seen') || 'false') === 'false',
  }),

  computed: {
    ...mapState({
      cashRegister: (state) => state.tjtheme.cashRegister,
      organization: (state) => state.tjtheme.organization,
      user: (state) => state.tjtheme.user,
      printer: (state) => state.printer.printer,
      drawer: (state) => state.tools.drawer,
      appInitialized: (state) => state.tools.appInitialized,
      cordovaBuildVersion: (state) => state.tools.cordovaBuildVersion,
    }),
  },

  methods: {
    ...mapActions({
      setDrawer: 'tools/setDrawer',
    }),
    setDrawer() {},
  },
}
</script>
<style lang="stylus">
.theme--dark.v-navigation-drawer .v-divider
  border-color #494952

.v-navigation-drawer--fixed .v-navigation-drawer__append
  padding-bottom env(safe-area-inset-bottom) !important

.v-navigation-drawer--fixed .v-navigation-drawer__content
  padding-top 50px !important

</style>
